import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import(/* webpackChunkName: "Main" */ '../components/Main')
    },
    {
        path: '',
        name: 'Layout',
        component: () => import(/* webpackChunkName: "Layout" */ '../components/Layout'),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import(/* webpackChunkName: "Dashboard" */ '../components/Home')
            },
            {
                path: '/senarios/:id/:time',
                name: 'Game',
                component: () => import(/* webpackChunkName: "Dashboard" */ '../components/Game')
            },
        ]
    }


]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
