import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'
import axios from 'axios'
import VueYouTubeEmbed from 'vue-youtube-embed'
import Vuelidate from 'vuelidate'
import VueAlertify from 'vue-alertify'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueYouTubeEmbed)
// if you don't want install the component globally
Vue.use(VueYouTubeEmbed, { global: false })
Vue.use(Vuelidate)
Vue.use(VueAlertify)
Vue.config.productionTip = false
// Vue.prototype.$url = 'http://localhost:8000/'
Vue.prototype.$url = 'https://api.horrorsurvive.com/'

Vue.prototype.$http =  axios.create({
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://api.horrorsurvive.com/api/',
  headers: { "Content-Type": "application/json","Access-Control-Allow-Origin":"*"}
});


Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
