
<template>
  <div id="app">

    <RouterView />
  </div>
</template>
<script>



export default {
  name: 'App',
  created: function () {
    this.$http.interceptors.response.use(response => response,error => {
      return new Promise(() => {
        if (error.response.status === 401) {
          this.$alertify.error("Not Autorized !");
          this.$router.push({'name':'Home'});
        }
        throw error;
      });
    });
  },

}
</script>
<style>
@font-face {
  font-family: "Monoton";
  src: url("./assets/Monoton-Regular.ttf");
}
body {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}

body::-webkit-scrollbar {
  width: 5px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  color: white!important;
}
body.main{
  background-image: url("./assets/im/1.jpg") ;
  background-size: cover;
  background-position: center;
}
body.home{
  background-image: linear-gradient(rgba(19,12,16,1),rgba(25,12,12,1)) !important;
}
body::-webkit-scrollbar-track {
  background: none;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
}

</style>





